.profile {
  overflow-x: hidden;
  overflow-y: scroll;
  height: calc(100vh - 200px);
  &__kyc--right {
    padding-top: 106px;
    @media screen and (max-width: 768px) {
      padding-top: 0;
    }
  }
  &__kyc--right-2 {
    padding-top: 145px;
    @media screen and (max-width: 768px) {
      padding-top: 0;
    }
  }
  &__button {
    border: 1px solid var(--main-color) !important;
    box-sizing: border-box !important;
    filter: drop-shadow(0px 2px 0px var(--main-five-teen-color)) !important;
    border-radius: 2px !important;
    font-weight: 500 !important;
    font-size: 16px !important;
    line-height: 24px !important;
    text-align: center;
    color: white !important;
    background: #047b73 !important;
    svg {
      margin-right: 8px;
      path {
        fill: #ffffff;
      }
    }
    &-second {
      background: var(--main-color) !important;
      color: var(--white-color) !important;
      svg {
        path {
          fill: var(--white-color) !important;
        }
      }
    }
    &-disabled {
      background-color: #f1f1f1 !important;
      border: 1px solid transparent !important;
    }
    &__cancle {
      background: transparent !important;
      border: 1px solid transparent !important;
      color: var(--main-twelfth-color) !important;
      margin-left: 10px;
    }
  }
  &__warning {
    padding: 9px 16px;
    background: #f1f1f1;
    border: 1px solid #f1f1f1;
    box-sizing: border-box;
    border-radius: 2px;
    color: var(---main-li-color);
    font-size: 14px;
    line-height: 22px;
    svg {
      margin-right: 10px;
    }
    margin-bottom: 60px;
  }
  &__text {
    color: var(---main-li-color);
    letter-spacing: 0.01em;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
  }
  &__title {
    font-size: 16px;
    line-height: 22px;
    color: var(--main-li-color);
  }
  &__boxLink {
    background: var(--main-four-color);
    border-radius: 6px;
    padding: 13px 8px;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.04em;
    text-align: center;
    color: var(--white-color);
    margin-top: 12px;
    margin-bottom: 40px;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  &__top {
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    width: 100%;
    height: 100px;
    background-image: url("/assets/imagesHome/factory_header.png");
    .factory__top__avatar {
      position: absolute;
      top: 50%;
    }
    &__title {
      position: absolute;
      top: 20%;
      padding-top: 10px;
      color: white;
      font-size: 16px;
      line-height: 20px;
      font-weight: bold;
    }
  }
  &__image-identify {
    width: 100%;
    /* Color #1/Gray #4 */

    background: #f5f5f5;
    /* Color #1/Gray #3 */

    border: 1px solid #d9d9d9;
    border-radius: 2px;
    padding: 8px;
    display: flex;
    align-items: center;
    gap: 8px;
    img {
      width: 48px;
      height: 48px;
      object-fit: cover;
    }
    .img-name {
      flex: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: #047b73;
    }
    .img-button {
      border: none;
    }
  }
  .packet__empty__list {
    background: rgba(255, 131, 20, 0.1);
    color: #ff8314;
  }
  .management__box__hr {
    height: 50px;
  }
}
.active{
  &-mail{
    &-title{
      font-weight: var(--font_weight_primary);
      display: flex;
      justify-content: center;
      font-size: var(--font_size_title_regular);
      line-height: 24px;
      padding: 5px 0 10px;
    }
    &-item{
      display: flex;
      justify-content: space-between;
      padding: 12px 0;
      align-items: center;
    }
    &-left{
      display: flex;
      gap: 8px;
      align-items: center;
    }
    &-right{
      cursor: pointer;
    }
  }
}
.user-mail{
  font-size: var(--font_size_text_regular);
  line-height: 20px;
  font-weight: var(--font_weight_secondary);
}
.create-date{
  line-height: 16px;
  font-size: var(--font_size_text_small);
  color: var(--text_color_primary_light);
}
