.introduce {
  background-color: var(--background_color_primary);
  padding-bottom: 50px;
  &_card {
    &_images {
      padding-top: 20px;
      padding-left: var(--space_rim_phone);
      padding-right: var(--space_rim_phone);
    }
    &_bugi {
      padding-top: 20px;
      padding-left: var(--space_rim_phone);
      padding-right: var(--space_rim_phone);
      &_tera {
        padding: 12px;
        &_note {
          display: flex;
          justify-content: center;
          align-items: center;
          &_icon {
            padding-right: 15px;
          }
          &_content {
            font-size: var(--font_size_text_regular);
            font-weight: 400;
            color: #b52bf6;
            line-height: 20px;
            &__qr {
              padding: 20px 0;
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }
        }
      }
    }
    &_qr {
      padding-bottom: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 160px;
        height: 160px;
        border-radius: 8px;
        border: 3px solid #f6932b;
      }
    }
    &_title {
      font-size: var(--font_size_text_small);
      font-weight: 500;
    }
    &_text {
      margin-left: 20px;
      font-size: var(--font_size_text_regular);
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      width: 300px;
      font-weight: 400;
    }
    font-weight: 400;
    &_folder {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      &_text {
        padding-left: var(--space_item_secondary);
        padding-right: var(--space_item_secondary);
        text-align: center;
        font-size: var(--font_size_text_regular);
        margin-bottom: 20px;
        line-height: 20px;
      }
      &_button {
        width: 65%;
        height: 40px !important;
        background: var(--color_primary_gradiant) !important;
        border-radius: 7px !important;
        color: var(--primary_button_text_color) !important;
        font-size: var(--font_size_text_regular) !important ;
        font-weight: 500 !important;
      }
    }
  }
}

// @media all and (max-width: 281px) {
//   .introduce_note_icon {
//     top: 22px;
//   }
// }
