.BasicInput {
  font-size: var(--font_size_text_regular) !important;
  color: var(--text_color_primary) !important;
  border: 1px solid var(--border_color_input) !important;
  border-radius: var(--border_radius_input) !important;
  height: 40px;
  padding: 4px 8px !important;

  &::placeholder {
    color: var(--text_color_placeholder) !important;
  }

  &:disabled {
    background-color: var(--background_color_disable) !important;
    color: var(--text_color_placeholder) !important;
  }

  .ant-input::placeholder {
    color: var(--text_color_placeholder) !important;
  }
}

.BasicInput[type="number"]::-webkit-inner-spin-button,
.BasicInput[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.BasicInput[type="number"] {
  -moz-appearance: textfield;
  /* Firefox */
}

.ant-input-affix-wrapper {
  input {
    &:hover {
      border: none !important;
    }
  }
}

.ant-input-affix-wrapper-disabled {
  background-color: var(--background_color_disable) !important;

  input {
    color: var(--text_color_placeholder) !important;
  }
}