.UserInfoBasic {
  display: flex;
  align-items: center;
  gap: 4px;

  .UserInfoBasic_logo {
    background-color: var(--background_color_component);
    border: 1px solid var(--text_color_primary_in_background);
    border-radius: 50%;
    object-fit: cover;
  }
  .UserInfoBasic_detail {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 100%;
    overflow: hidden;

    .UserInfoBasic_detail_name,
    .UserInfoBasic_detail_level {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      overflow: hidden;
    }
    .UserInfoBasic_detail_level {
      font-size: 10px;
      display: flex;
      white-space: nowrap;
      align-items: center;
      gap: 4px;
      background: linear-gradient(
        92.04deg,
        #4c361f -1.84%,
        #3b2610 38.39%,
        #261300 100%
      );
      width: fit-content;
      padding: 0px 8px 0 6px;
      border-radius: 40px;
      color: var(--text_color_primary_in_background);
    }
    .UserInfoBasic_detail_name {
      font-weight: var(--font_weight_secondary);
      line-height: 20px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      overflow: hidden;
      word-wrap: break-word;
    }
  }
}
