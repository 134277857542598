.paddingFooter {
  padding-bottom: 65px;
}

.layout {
  min-height: 100vh;
  position: relative;
  background-color: var(--background_color_primary);
  margin: auto;
  overflow-x: hidden;
  overflow-y: auto;
  max-width: var(--max_width_screen_project);
  width: 100%;

  @media screen and (max-width: 480px) {
    max-width: 100%;
    min-width: 100%;
  }

  ul,
  ol,
  li {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }
}