.register {
  #login {
    margin-top: 30px;
    .ant-row {
      display: block;
    }
  }
  .ant-form-item-label > label {
    font-size: var(--font_size_text_small);
  }
  .ant-input {
    border: 1px solid var(--border_color_input);
    border-radius: 4px;
  }
  .ant-input,
  .ant-input:hover,
  .ant-input-affix-wrapper,
  .ant-input-number,
  .ant-input-number:hover {
    border: 1px solid var(--border_color_input);
    border-radius: 4px;
  }
  .inputBasic {
    width: 100%;
    font-size: 16px;
    height: 40px;
  }
  .hiden-border {
    .ant-input {
      border: none;
    }
  }
  .ant-form-item {
    margin-bottom: 0px;
  }
  .tracker {
    position: relative;
    &-box {
      position: absolute;
      background-color: #fff;
      z-index: 2;
      border-radius: 2px;
      padding: 10px 5px 5px 5px;
      box-shadow: 0 2px 4px 0 var(--color_black_opacity_1);
      background: var(--color_background);
    }
  }
  &__footer {
    font-size: var(--font_size_text_regular);
    text-align: center;
    line-height: 24px;
    margin-top: 20px;
    font-weight: 500;
    &--text {
      color: var(--color_primary);
      font-weight: var(--font_weight_secondary);
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  .error-mess {
    font-size: var(--font_size_text_small);
    color: var(--color_danger);
    margin-top: 2px;
    height: 20px;
  }
  &-btn {
    font-size: var(--font_size_text_regular);
    margin-top: 10px;
    border-radius: 6px;
  }
  &-btn:hover,&-btn:focus {
    font-size: var(--font_size_text_regular);
    margin-top: 10px;
    border-radius: 6px;
    background: var(--color_primary_gradiant);
    color: var(--text_color_primary_in_button);
    border: 1px solid var(--color_primary_gradiant);
  }
  &__title {
    font-weight: bold;
    font-size: var(--font_size_title_xxl);
    font-weight: var(--font_weight_primary);
    line-height: 40px;
    margin-bottom: 8px;
  }
  &-center {
    background-color: #fff;
    width: 100%;
    bottom: 0;
    padding: 60px 20px;

    .login__title {
      margin-top: 40px;
    }

    .ant-tabs-nav {
      display: none;
    }

    .ant-tabs.ant-tabs-top {
      max-width: 450px;
      margin-left: auto;
      margin-right: auto;
    }

    .login__subTitle {
      margin-bottom: 36px;
    }

    &.full {
      height: 100%;
      border-radius: 0px;
    }

    &.small {
      height: 100%;

      form {
        width: 100%;

        input {
          text-align: center;
          border: 2px solid rgba(4, 123, 115, 1);
        }

        .ant-input-password {
          border: 2px solid rgba(4, 123, 115, 1);
          input {
            border: none;
          }
        }
      }
    }
  }

  &-otp {
    width: 100%;
    display: flex;
    justify-content: center;
    img {
      width: 120px;
      height: 120px;
    }
  }

  &-success {
    display: flex;
    justify-content: center;
    flex-direction: column;
    img {
      width: 140px;
      height: 140px;
    }
  }

  .login {
    &-center {
      padding: 38px;
      height: auto;
    }
  }
  .buttonConfirm {
    height: 24px;
    line-height: 16px;
    display: flex;
    font-size: var(--font_size_text_regular);
    font-weight: var(--font_weight_secondary);
    justify-content: center;
    align-items: center;
    background: var(--color_primary);
    border-radius: 4px;
    cursor: pointer;
    min-width: 60px;
    padding: 0 5px;
  }
  @media (max-height: 740.5px) {
    height: calc(100% + 150px);
  }

  @media (max-height: 667.5px) {
    height: calc(100% + 230px);
  }

  @media (max-height: 414.5px) {
    height: calc(100% + 420px);
  }
}

.backIcon {
  svg {
    width: 24px;
    height: 24px;
    margin: 0;
  }
}
.agree-checked{
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: var(--color_primary);
    border-color: var(--color_primary);
  }
  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: var(--color_primary);
  }
  .agree{
    color: var(--color_primary) !important;
  }
}
.agree-content{
  display: flex;
  justify-content: space-between;
  padding: 12px 0;
  align-items: center;
  p{
    margin-bottom: 8px !important;
  }
}
@media (max-width: 480px) {
  .register {
    .ant-form-item .ant-form-item-label {
      padding: 0px !important;
    }
  }
}
