.BasicSelect {
  font-size: var(--font_size_text_regular) !important;
  color: var(--text_color_primary) !important;
  border-radius: var(--border_radius_input) !important;
  height: 40px;
  width: 100%;

  .ant-select-selection-item {
    color: var(--text_color_primary) !important;
  }

  .ant-select-selection-placeholder {
    color: var(--text_color_placeholder) !important;
  }

  .ant-select-selector {
    border-radius: var(--border_radius_input) !important;
  }
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-color: var(--color_primary) !important;
  box-shadow: none !important;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: var(--background_color_active) !important;
}

.BasicSelect_search,
.BasicSelect_menu {
  padding: 6px 8px;
}

.ant-select-item {
  position: relative;
  font-size: var(--font_size_text_regular) !important;
  line-height: 20px !important;

  &::before {
    content: "";
    position: absolute;
    top: 0px;
    right: 0;
    left: 0;
    width: 100%;
    height: 0;
    border-color: var(--border_card_color);
    border-top-style: dashed;
    border-top-width: 1px;
  }
}