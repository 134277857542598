.login {
  &-icon {
    .ant-input:hover {
      border: none !important;
    }
  }
  &-btn {
    font-size: var(--font_size_text_regular);
  }
  &-center {
    margin: auto;
    background-color: #fff;
    max-width: 450px;
    padding: 60px 20px;
    height: 100vh;
    #login {
      .ant-form-item:first-child{
        margin-bottom: 0;
      }
      margin-top: 40px;
      .ant-form-item-explain-error {
        text-align: left;
      }
      .ant-input-affix-wrapper,
      .ant-input-number,
      .ant-input-number:hover {
        border-width: 1px;
        border-style: solid;
        border-radius: 2px;
        border-color: var(--border_color_input);
        border-radius: 4px;
      }
    }
    .error-login {
      display: flex;
      gap: 6px;
      align-items: center;
      padding: 8px;
      border: 1px solid var(--color_primary_light_border);
      border-radius: 4px;
      background-color: var(--color_primary_light);
      color: var(--color_primary_dark);
      &-text {
        line-height: 20px;
      }
    }
    .ant-tabs-nav {
      display: none;
    }

    input {
      border: 2px solid rgba(4, 123, 115, 1);
    }
  }
  &__title {
    font-weight: bold;
    font-size: var(--font_size_title_xxl);
    font-weight: var(--font_weight_primary);
    line-height: 40px;
    margin-bottom: 8px;
  }
  &__input {
    padding-left: 30px !important;
    .ant-input-suffix {
      display: none;
    }
    &__icon {
      position: relative;
      .top-3 {
        svg {
          top: 36px;
        }
      }
      svg {
        position: absolute;
        top: 9px;
        z-index: 2;
        left: 11px;
        width: 20px;
        height: 20px;
        path {
          fill: #f6932b;
        }
      }
    }
  }
  &__parent {
    margin-top: 25px;
    margin-bottom: 40px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 10px 0;
    label {
      font-size: var(--font_size_text_regular);
    }
    .ant-checkbox-checked .ant-checkbox-inner {
      background-color: var(--color_primary);
      border-color: var(--color_primary);
    }
    .ant-checkbox-wrapper:hover .ant-checkbox-inner,
    .ant-checkbox:hover .ant-checkbox-inner,
    .ant-checkbox-input:focus + .ant-checkbox-inner {
      border-color: var(--color_primary);
    }
  }
  &__forget {
    color: var(--color_primary);
    font-style: normal;
    font-weight: var(--font_weight_secondary);
    font-size: 14px;
    line-height: 22px;
    margin-left: 0;
    text-decoration: underline;
    cursor: pointer;
  }
  &__footer {
    font-size: var(--font_size_text_regular);
    text-align: center;
    line-height: 24px;
    font-weight: 500;
    margin-top: 20px;
    &--text {
      color: var(--color_primary);
      font-weight: 500;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  .text-lower{
    text-transform: lowercase;
  }

  @media (max-height: 414.5px) {
    &-container {
      height: calc(100% + 260px);
    }
  }

  @media (max-height: 280.5px) {
    &-container {
      height: calc(100% + 390px);
    }
  }
}
.authenticator__2fa {
  &__auth {
    justify-content: space-between;
    align-items: center;
    &__grp {
      &--title {
        color: #222222;
        font-weight: 500;
        font-size: 15px;
        line-height: 20px;
      }
      &--top-content,
      &--bottom-content {
        color: #616161;
        font-weight: 300;
        font-size: 15px;
        line-height: 20px;
      }
      &--code {
        color: #047b73;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
      }
      &--shied-img {
        margin-top: 20px;
      }
      &--confirm-title {
        text-align: center;
        font-weight: 600;
        font-size: 28px;
        line-height: 32px;
        margin-bottom: 30px;
      }
      &--confirm-content {
        text-align: center;
        font-weight: 400;
        font-size: 15px;
        line-height: 20px;
        margin-bottom: 20px;
      }
    }
  }
  &--btn {
    width: 100%;
    height: 40px;
    margin-top: 50px;
    background: #047b73;
    border: 1px solid #047b73;
    border-radius: 2px;
    color: #ffffff;
  }
}
.error-mess {
  font-size: var(--font_size_text_small);
  color: var(--color_danger);
  margin-top: 2px;
  height: 20px;
}
