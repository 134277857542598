$boxShadowCircleInColor: #FFA9451F;
$boxShadowCircleOutColor: #F6932B1F;
$boxShadowCircleOutItem: 0px 0px 8px 0px #F6932BBF, 0px 1px 2px 0px #0000001F;

#IconChatService {
  position: fixed;
  bottom: 0;
  z-index: 9999;
  width: 100%;

  .chatIcon {
    position: absolute;

    img {
      height: 40px;
      width: 40px;
      background: var(--background_color_component);
      border-radius: 50%;
    }

    .circle {
      display: inline-block;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      position: absolute;
      animation: ripple 2s infinite;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      box-shadow: $boxShadowCircleOutItem;
    }
  }

  .chatCustomerService {
    bottom: 30px;
    right: var(--space_item_primary);
  }

  @keyframes ripple {
    0% {
      box-shadow: 0 0 0 0 $boxShadowCircleInColor,
        0 0 0 5px $boxShadowCircleOutColor;
    }

    100% {
      box-shadow: 0 0 0 5px $boxShadowCircleInColor,
        0 0 0 10px $boxShadowCircleOutColor;
    }
  }
}