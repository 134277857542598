.card {
  background: #ffffff;
  border: 2px solid #f1f1f1;
  box-sizing: border-box;
  box-shadow: 0px 4px 20px rgba(1, 12, 41, 0.04);
  border-radius: 18px;
  padding: 28px;
}

.support_note {
  font-weight: 400;
  font-size: var(--font_size_text_regular);
  padding-bottom: 20px;
  padding-top: 20px;
  border-radius: 25px;
  text-align: center;
  line-height: 20px;
}

.support_area {
  margin-top: 10px;
  background: var(--text_color_primary_in_background);
  border-radius: 10px;
}

.support_text {
  padding-left: 15px;
}

a {
  text-decoration: none;
}

.modal-support {
  .ant-modal-header {
    .ant-modal-title {
      .header-mobile__container {
        padding: 2rem 1rem 1rem 2rem !important;
      }
    }
  }
  .ant-modal-body {
    .wrapp-support-area {
      .wrapp-text {
        border: 1px solid #f4f4f4;
      }
      .support-area {
        border: 1px solid #f4f4f4;
        a {
          text-decoration: none;
        }
      }
    }
  }
}
.modal-setting {
  .ant-modal-header {
    .ant-modal-title {
      .header-mobile__container {
        padding: 2rem 1rem 1rem 2rem !important;
      }
    }
  }
}
