.swal2-shown {
  padding-right: 0 !important;

  &:not(.swal2-no-backdrop):not(.swal2-toast-shown) {
    overflow: auto;
  }

  div:where(.swal2-container) button:where(.swal2-styled).swal2-confirm:focus,
  div:where(.swal2-container) button:where(.swal2-close):focus {
    box-shadow: none;
  }
}

.BasicAlertPopup_icon {
  border: none;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: -100px;
  height: 120px;
  width: 120px;
  z-index: 1;
}

.BasicAlertPopup_popup {
  border-radius: var(--border_radius_card);
  width: 90% !important;
  padding: 72px 16px 20px 16px;
}

.BasicAlertPopup_title {
  color: var(--text_color_primary_in_background);
  font-size: var(--font_size_title_large);
  font-weight: var(--font_weight_primary);
  padding: 0 var(--space_item_primary) 8px;
}

.BasicAlertPopup_content {
  color: var(--text_color_primary_in_background);
  font-size: var(--font_size_text_regular);
  line-height: 20px;
}

.BasicAlertPopup_actions {
  width: 100%;
  margin: 20px 0 0 0;
}

.BasicAlertPopup_cancelButton {
  margin: 0 4px;
  width: calc(50% - 8px);
  padding: 8px;
  height: 40px;
  cursor: pointer;
  border-radius: var(--border_radius_button) !important;
  background: var(--background_color_component) !important;
  border-color: var(--border_color_input) !important;
  color: var(--text_color_primary_light) !important;
}

.BasicAlertPopup_confirmButton {
  margin: 0 4px;
  width: 100%;
  height: 40px;
  padding: 8px;
  cursor: pointer;
  border-radius: var(--border_radius_button) !important;
  background: var(--background_color_component) !important;
  color: var(--color_primary) !important;
  box-shadow: none;
}

.BasicAlertPopup_confirmButton_withCancel {
  margin: 0 4px;
  width: calc(50% - 8px);
  height: 40px;
  padding: 8px;
  cursor: pointer;
  border-radius: var(--border_radius_button) !important;
  background: var(--color_primary_gradiant) !important;
  color: var(--text_color_primary_in_background) !important;
  box-shadow: none;
}

.BasicAlertPopup_confirmButton_withCancel {
  margin: 0 4px;
  width: calc(50% - 8px);
  height: 40px;
  padding: 8px;
  cursor: pointer;
  border-radius: var(--border_radius_button) !important;
  background: var(--color_primary_gradiant) !important;
  color: var(--text_color_primary_in_background) !important;
  box-shadow: none;
}

.BasicAlertPopup_closeButton {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%) !important;
  height: 28px;
  width: 28px;
  cursor: pointer;
}

// blur
.BasicAlertPopup_container {
  width: 100%;
  margin: 0 auto !important;
  z-index: 999999;
}

.fullHeightWidth {
  height: 100%;
  width: 100%;
}

.BasicAlertPopup_popup_no_icon {
  padding-top: var(--space_item_secondary);
}

.BasicAlertPopup_footer {
  position: absolute;
  display: block;
  bottom: 0;
  transform: translateY(100%);
  padding: 8px;
  border: none;
  width: 100%;
  text-align: center;
  left: 0;
  word-wrap: break-word;
  color: var(--background_color_component);
  font-size: var(--font_size_text_small) !important;
}