.branch {
  .BasicSegmented {
    margin-top: -52px;
  }
  .BasicSegmented_background {
    border: none;
  }
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 27px 20px 28px;
    background: var(--main-color);
    border: 1px solid rgba(20, 20, 50, 0.2);
    margin-bottom: 19px;
    @media screen and (max-width: 768px) {
      justify-content: center;
      align-items: flex-start;
      & > * + * {
        padding-left: 32px;
      }
    }
  }
  &__item {
    display: flex;
    justify-content: center;
    color: var(--white-color);
    align-items: center;
    @media screen and (max-width: 768px) {
      justify-content: flex-start;
      margin-top: 16px;
    }
    &-right {
      margin-left: 12px;
      p {
        margin: 0;
        padding: 0;
        &:nth-child(1) {
          font-weight: var(--font_weight_third);
          font-size: var(--font_size_title_regular);
          line-height: 24px;
          text-transform: uppercase;
        }
        &:nth-child(2) {
          font-weight: var(--font_weight_primary);
          font-size: var(--font_size_title_extra_large);
          line-height: 30px;
        }
      }
    }
  }
  .branch-title {
    p.amount {
      max-width: 130px;
      font-weight: var(--font_weight_secondary);
      font-size: var(--font_size_title_regular);
      line-height: 20px;
    }
  }

  .management__box {
    padding-bottom: 150px;
  }
  .icon {
    color: var(--icon_color_red);
    margin-left: 6px;
    cursor: pointer;
    svg {
      margin-top: -10px;
    }
  }
  &__search {
    .ant-input-affix-wrapper {
      width: 60%;
      border-color: #dbdddd;
      border-radius: 1px;
    }
  }
  .ant-table-wrapper {
    .ant-table-thead {
      .ant-table-cell {
        background: #f8f8f8 !important;
        font-weight: 500 !important;
        font-size: 14px !important;
        line-height: 22px !important;
      }
    }
    .ant-table-tbody {
      tr {
        td {
          border-bottom: 1px solid rgba(0, 0, 0, 0.12) !important;
        }
      }
    }
  }
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  height: 40px;
}

.ant-select-selection-item {
  display: flex;
  align-items: center;
  color: var(--text_color_primary_light);
}

.ant-pagination-item-active {
  background-color: var(--background_color_blur);
  color: var(--text_color_primary_in_button);
  border: none;
}

.ant-pagination-prev .ant-pagination-item-link, .ant-pagination-next .ant-pagination-item-link {
  border-radius: 6px;
}

.ant-tabs > .ant-tabs-nav {
  margin-bottom: 0;
  .ant-tabs-ink-bar {
    background-color: var(--color_primary);
  }

  .ant-tabs-tab:hover {
    color: var(--color_primary);
  }
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: var(--color_primary)
}

.lobby-date-range {
  .ant-form-item-control-input-content {
    display: flex;
    
    .ant-picker {
      flex: 1;
    }
  }
}

.lobby-select {
  .ant-select-selection-item {
    color: var(--text_color_primary)
  }
}