.BasicSegmented {
  position: relative;
  padding: 12px 12px 0;

  .BasicSegmented_background {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    border-top: 1px solid var(--color_white_opacity_3);
  }

  .ant-segmented {
    background: transparent;
    border-radius: 8px 8px 0 0;
    padding: 0;
    &:hover {
      background: transparent !important;
    }

    .ant-segmented-item {
      border-radius: 8px 8px 0 0;
      height: 40px;
      font-size: var(--font_size_text_regular);

      line-height: 20px;
    }
    .ant-segmented-thumb {
      border-radius: 8px 8px 0 0;
      &::before {
        content: "";
        position: absolute;
        width: 100px;
        height: 3px;
        border-radius: 8px 8px 0 0;
        width: 85%;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
      }
    }
    .ant-segmented-item-selected {
      font-weight: var(--font_weight_secondary);
      position: relative;
      &::before {
        content: "";
        position: absolute;
        width: 100px;
        height: 3px;
        border-radius: 8px 8px 0 0;
        width: 85%;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
}
