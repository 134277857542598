html {
  // cái này check nếu bé hơn thì sẽ 100% width lớn hơn thì sẽ mặc định tối đa 375px
  --max_width_phone: 480px;

  // font family
  --font_family: "Barlow", sans-serif;

  //max width full screeen
  --max_width_screen_project: 375px;

  //font size
  --font_size_text_small: 12px;
  --font_size_text_regular: 15px;
  --font_size_title_regular: 18px;
  --font_size_title_large: 20px;
  --font_size_title_extra_large: 22px;
  --font_size_title_xxl: 30px;

  // font-weight
  --font_weight_bold: 700; // title, ...
  --font_weight_primary: 600; // title, ...
  --font_weight_secondary: 500; // button, ...
  --font_weight_third: 400; // button, ...

  //padding - margin
  --space_rim_phone: 12px; // rìa mép màn hình
  --space_card: 12px; // padding card
  --space_item: 12px; // in item
  --space_item_between: 20px; // khoảng cách 2 item
  --space_item_primary: 12px;
  --space_item_secondary: 20px;
  --space_item_tertiary: 40px; // 2 cụm khác nhau
  // không theo mục đích hoặc từng phần không trùng nhau thì dùng bootstrap hoặc css

  // border radius
  --border_radius_input: 4px;
  --border_radius_button: 6px;
  --border_radius_card: 12px;
  --border_radius_small_card: 8px;

  // box shadow
  --box_shadow_primary: 0px 4px 20px 0px #ff7816;
  --box_shadow_secondary: 0px 4px 20px 0px #7701ff80;
  --box_shadow_danger: 0px 4px 20px 0px #df302740;
  --box_shadow_success: 0px 4px 20px 0px #389e0d80;
  --box_shadow_info: 0px 4px 20px 0px #0a54c4bf;
  --box_shadow_step: 0px 1px 0px 0px #E2E2E280 inset;
  --box_shadow_card: 0px 4px 20px 0px #180D0114;
  ;

  // image banner
  --aspect_ratio_banner: 16/9;
  --aspect_ratio_notification: 21/9;
}

[data-theme="PITCOIN"] {
  --color_primary: #f6932b;
  --color_primary_dark: #ff7816;
  --color_error: #D3170B;
  --color_primary_light: #fff7e6;
  --color_primary_light_border: #ffd591;
  --color_primary_light_bg: #fff7e6;
  --color_button_bg: #FBF9F7;
  --color_primary_gradiant: linear-gradient(135deg,
      #ffd362 0%,
      #f6932b 53%,
      #f16601 100%);

  --color_secondary: #884ef5;
  --color_secondary_light: #f9f0ff;
  --color_secondary_gradiant: linear-gradient(135deg,
      #cd8cea 9.5%,
      #884ef5 56.5%,
      #7e46eb 100%);
  --color_dark_gradiant: linear-gradient(92.04deg,
      #4C361F -1.84%,
      #3B2610 38.39%,
      #261300 100%);

  --color_staking_gradiant: linear-gradient(135deg, #FFEB49 9.5%, #FFD300 48%, #EC7F00 100%);
  --color_staking_total_chart_distribution: #F6932B;
  --color_staking_title_chart_distribution: #180D01;

  --color_danger: #ff4138;
  --color_danger_light: #ffe8e6;
  --color_danger_gradiant: linear-gradient(135deg,
      #ffa99e 11.5%,
      #ff4138 59%,
      #c70808 100%);
  --color_danger_gradiant_popup: linear-gradient(314.29deg,
      #dc1f18 0%,
      #f8954f 101.27%);

  --color_success: #4fc814;
  --color_success_light: #e6ffcd;
  --color_success_gradiant: linear-gradient(135deg,
      #aaef7b 20%,
      #4fc814 60.5%,
      #389e0d 91%);

  --color_info: #00a4ff;
  --color_info_light: #6ce5ff;
  --color_info_gradiant: linear-gradient(135deg,
      #6ce5ff 5.5%,
      #28b2ff 49.5%,
      #0a54c4 96%);

  --text_color_primary: #180d01;
  --text_color_primary_light: #868381;
  --text_color_placeholder: #868381;
  --text_color_primary_in_background: #ffffff; //card
  --text_color_primary_in_button: #ffffff;
  --background_color_primary: #f5f4f3;
  --background_color_opacity: #ffffff77;
  --background_color_blur: #1e1307;
  --background_color_blur_opacity: rgba(30, 19, 7, 0.9);
  --background_color_disable: #f5f4f3;
  --background_color_component: #ffffff;
  --background_color_active: #fff8f0;
  --border_color_input: #e2e2e2;
  --background_component_opacity: #ffffff14;
  --background_list_item_color: #FBF9F7;
  --border_input_color: #d9d9d9;
  --border_card_color: #F3EDE7;
  --tag_text_color: #BEBCBA;

  //color prize
  --background_item_prize: linear-gradient(#fee45a, #fea613);
  --background_item_prize_two: linear-gradient(#e6e6e6, #999999);
  --background_item_prize_three: linear-gradient(#fbc56d, #c5715a);
  --background_header_prize: #f8954f;
  --color_prize_gradiant: linear-gradient(105deg, #f8954f, #dc1f18);
  --color_border: #dca0f8;

  //color splash
  --color_white_opacity_3: rgba(255, 255, 255, 0.3);
  --color_white_opacity_1: rgba(255, 255, 255, 0.15);
  --color_black_opacity_1: rgba(0, 0, 0, 0.15);
  --color_black_blur: #261301;
  --color_light_radial_gradient: radial-gradient(circle,
      rgba(254, 254, 254, 0.16),
      rgba(255, 255, 255, 0));

  // popup color
  --color_background: #fff8f0;

  // màu nền ngoài bọc app
  --background_color_browser: #e9e9e9;

  // icons
  --icon_color_red: #ed3c37;
  --icon_remove_color: #FF2E00;

  // header
  --header_width_large: 110px;
  --header_absolute_top: -60px;

  --text-color-green: #0FBF00; // Used in staking screen

  // wallet bonus
  --bonus_icon_outline_size: 40px;
  --bonus_icon_outline_radius: 100px;
  --bonus_icon_outline_radius: 100px;
  --bonus_icon_outline_background_color: #FFF0F6;
  --bonus_banner_height: 256px;

}