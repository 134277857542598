//------------- max width ------------/
.max_width_screen_project {
  width: 100%;
  max-width: var(--max_width_screen_project);

  @media screen and (max-width: 480px) {
    max-width: 100%;
    min-width: 100%;
  }
}

//------------- background ------------/
.background_primary {
  background: var(--color_primary);
  color: var(--text_color_primary_in_button);
  border: 1px solid var(--color_primary);
}

.background_secondary {
  background: var(--color_secondary);
  color: var(--text_color_primary_in_button);
  border: 1px solid var(--color_secondary);
}

.background_danger {
  background: var(--color_danger);
  color: var(--text_color_primary_in_button);
  border: 1px solid var(--color_danger);
}

.background_success {
  background: var(--color_success);
  color: var(--text_color_primary_in_button);
  border: 1px solid var(--color_success);
}

.background_info {
  background: var(--color_info);
  color: var(--text_color_primary_in_button);
  border: 1px solid var(--color_info);
}

.background_primary_gradiant {
  background: var(--color_primary_gradiant);
  color: var(--text_color_primary_in_button);
  border: 1px solid var(--color_primary_gradiant);
}

.background_secondary_gradiant {
  background: var(--color_secondary_gradiant);
  color: var(--text_color_primary_in_button);
  border: 1px solid var(--color_secondary_gradiant);
}

.background_danger_gradiant {
  background: var(--color_danger_gradiant);
  color: var(--text_color_primary_in_button);
  border: 1px solid var(--color_danger_gradiant);
}

.background_danger_gradiant_popup {
  background: var(--color_danger_gradiant_popup);
  color: var(--text_color_primary_in_button);
  border: 1px solid var(--color_danger_gradiant);
}

.background_success_gradiant {
  background: var(--color_success_gradiant);
  color: var(--text_color_primary_in_button);
  border: 1px solid var(--color_success_gradiant);
}

.background_info_gradiant {
  background: var(--color_info_gradiant);
  color: var(--text_color_primary_in_button);
  border: 1px solid var(--color_info_gradiant);
}

.background_primary_outlined {
  background: var(--color_primary_light);
  color: var(--color_primary);
  border: 1px solid var(--color_primary_light_border);
}

.background_secondary_outlined {
  background: var(--color_secondary_light);
  color: var(--color_secondary);
  border: 1px solid var(--color_secondary);
}

.background_danger_outlined {
  background: var(--color_danger_light);
  color: var(--color_danger);
  border: 1px solid var(--color_danger);
}

.background_success_outlined {
  background: var(--color_success_light);
  color: var(--color_success);
  border: 1px solid var(--color_success);
}

.background_info_outlined {
  background: var(--color_info_light);
  color: var(--color_info);
  border: 1px solid var(--color_info);
}

.background_transparent {
  background: transparent;
}

.background_transparent_gradiant {
  background: transparent;
}

//box shadow
.box_shadow_primary {
  box-shadow: var(--box_shadow_primary);
}

.box_shadow_secondary {
  box-shadow: var(--box_shadow_secondary);
}

.box_shadow_danger {
  box-shadow: var(--box_shadow_danger);
}

.box_shadow_success {
  box-shadow: var(--box_shadow_success);
}

.box_shadow_info {
  box-shadow: var(--box_shadow_info);
}

//background Top
.background_top_radius {
  border-radius: 0px 0px 28px 28px;
  overflow: hidden;
}

.isDisable {
  pointer-events: none;
  opacity: 0.6;
}

.word-break-all {
  word-break: break-word;
}

.text-align-right {
  text-align: right;
}

@media (max-width:320px) {
  .otp {
    &__input-otp {
      input {
        width: 32px !important;
        height: 32px;
      }
    }
  }
}

.dot_notification {
  width: 16px;
  height: 16px;
  background-color: var(--color_danger);
  color: var(--text_color_primary_in_background);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: calc(var(--font_size_text_small) - 4px);
  font-weight: var(--font_weight_primary);
}