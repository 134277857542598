.prize {
  background: var(--color_prize_gradiant);
  &_image {
    position: relative;
    top: 5px;
    &_ray {
      position: absolute;
      top: -7px;
    }
    &_res {
      position: absolute;
      width: 45%;
      top: 45px;
      right: -35px;
      z-index: 2;
    }
  }
  &_card {
    background: var(--color_secondary_gradiant);
    border: 2px solid var(--color_border);
    .ant-card-body {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 0;
    }
    &_top {
      position: absolute;
      width: 83%;
      top: -8px;
    }
    &_image {
      margin-top: -8px;
      z-index: 1;
    }
    &_rank {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      height: 140px;
      padding-top: 8px;
      &_zaby {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        margin-bottom: 50px;
        position: relative;
        &_count {
          display: flex;
          justify-content: center;
          align-items: center;
          position: absolute;
          top: 42px;
          left: calc(20% - 35px);
          &_text {
            padding-left: 20px;
            padding-right: 12px;
            border-radius: 13px;
            border: 1.5px solid #e6e6e6;
            background: var(--text_color_primary);
            color: var(--text_color_primary_in_button);
            font-size: var(--font_size_text_regular);
            position: absolute;
            left: 13px;
          }
          &_icon {
            z-index: 1;
            &_khung {
              position: absolute;
              top: -21px;
              left: -8px;
            }
            &_name {
              z-index: 1;
              position: absolute;
              top: -14px;
              left: 0px;
            }
          }
        }
        &_ruby {
          display: flex;
          position: absolute;
          top: 8px;
          left: calc(50% - 40px);
          &_text {
            padding-left: 20px;
            padding-right: 12px;
            border-radius: 13px;
            border: 1.5px solid #fee45a;
            background: var(--text_color_primary);
            color: var(--text_color_primary_in_button);
            font-size: var(--font_size_text_regular);
            position: absolute;
            left: 13px;
          }
          &_icon {
            z-index: 1;
            &_khung {
              position: absolute;
              top: -8px;
              left: -8px;
            }
            &_name {
              z-index: 1;
              position: absolute;
              top: -1px;
              left: 0px;
            }
          }
        }
        &_tanba {
          display: flex;
          position: absolute;
          top: 30px;
          left: calc(80% - 35px);
          &_text {
            padding-left: 20px;
            padding-right: 12px;
            border-radius: 13px;
            border: 1.5px solid #fbc56d;
            background: var(--text_color_primary);
            color: var(--text_color_primary_in_button);
            font-size: var(--font_size_text_regular);
            position: absolute;
            left: 7px;
          }
          &_icon {
            z-index: 1;
            &_khung {
              position: absolute;
              top: -3px;
              left: -8px;
            }
            &_name {
              z-index: 1;
              position: absolute;
              top: 0px;
              left: -4px;
            }
          }
        }
      }
    }
    &_list {
      padding-left: 8px;
      padding-right: 8px;
      width: 100%;
      box-shadow: 0px -25px 20px -20px rgba(0, 0, 0, 0.2);
      &_item {
        padding: 12px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        &_pay {
          display: flex;
          justify-content: space-between;
          align-items: center;
          &_image {
            width: 48px;
            height: 48px;
          }
          &_text {
            display: flex;
            flex-direction: column;
            width: 120px;
            height: 40px;
            padding-left: 7px;
            &_one {
              font-size: var(--font_size_text_regular);
              font-weight: var(--primary_button_text_font_weight);
              display: -webkit-box;
              -webkit-line-clamp: 1;
              -webkit-box-orient: vertical;
              overflow: hidden;
              text-overflow: ellipsis;
            }
            &_two {
              font-size: var(--font_size_text_small);
              font-weight: var(--font_weight_secondary);
              color: red;
            }
          }
        }
        &_hair {
          width: 40px;
          height: 40px;
          margin-right: -100px;
        }
      }
      &_price {
        padding: 12px;
        width: 100%;
        padding-top: 0px;
        &_anpha {
          padding: 8px;
          border-radius: 8px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          background: var(--background_item_prize);
          &_text1 {
            font-size: var(--font_size_text_small);
            font-weight: 400;
            color: var(--text_color_primary_in_button);
          }
          &_text3 {
            font-size: var(--font_size_text_regular);
            font-weight: 600;
            color: var(--text_color_primary_in_button);
            text-align: left;
          }
          &_text2 {
            font-size: var(--font_size_text_regular);
            font-weight: 600;
            color: var(--text_color_primary_in_button);
            text-align: right;
          }
        }
      }
    }
    &_type {
      padding-left: 8px;
      padding-right: 8px;
      margin-top: 12px;
      width: 100%;
      &_item {
        padding: 12px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        &_pay {
          display: flex;
          justify-content: space-between;
          align-items: center;
          &_text {
            display: flex;
            flex-direction: column;
            width: 120px;
            height: 40px;
            padding-left: 7px;
            &_one {
              font-size: var(--font_size_text_regular);
              font-weight: var(--primary_button_text_font_weight);
              display: -webkit-box;
              -webkit-line-clamp: 1;
              -webkit-box-orient: vertical;
              overflow: hidden;
              text-overflow: ellipsis;
            }
            &_two {
              font-size: var(--font_size_text_small);
              font-weight: var(--primary_button_text_font_weight);
              color: red;
            }
          }
        }
        &_hair {
          width: 40px;
          height: 40px;
          margin-right: -100px;
        }
      }
      &_price {
        padding: 12px;
        width: 100%;
        padding-top: 0px;
        &_anpha {
          padding: 8px;
          border-radius: 8px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          background: var(--background_item_prize_two);
          &_text1 {
            font-size: var(--font_size_text_small);
            font-weight: 400;
            color: var(--text_color_primary_in_button);
          }
          &_text2 {
            font-size: var(--font_size_text_regular);
            font-weight: 600;
            color: var(--text_color_primary_in_button);
            text-align: right;
          }
          &_text3 {
            font-size: var(--font_size_text_regular);
            font-weight: 600;
            color: var(--text_color_primary_in_button);
            text-align: left;
          }
        }
      }
    }
    &_captain {
      padding-left: 8px;
      padding-right: 8px;
      margin-top: 12px;
      margin-bottom: 16px;
      width: 100%;
      &_item {
        padding: 12px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        &_pay {
          display: flex;
          justify-content: space-between;
          align-items: center;
          &_text {
            display: flex;
            flex-direction: column;
            width: 120px;
            height: 40px;
            padding-left: 7px;
            &_one {
              font-size: var(--font_size_text_regular);
              font-weight: var(--primary_button_text_font_weight);
              display: -webkit-box;
              -webkit-line-clamp: 1;
              -webkit-box-orient: vertical;
              overflow: hidden;
              text-overflow: ellipsis;
            }
            &_two {
              font-size: var(--font_size_text_small);
              font-weight: var(--primary_button_text_font_weight);
              color: red;
            }
          }
        }
        &_hair {
          width: 40px;
          height: 40px;
          margin-right: -100px;
        }
      }
      &_price {
        padding: 12px;
        width: 100%;
        padding-top: 0px;
        &_anpha {
          padding: 8px;
          border-radius: 8px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          background: var(--background_item_prize_three);
          &_text1 {
            font-size: var(--font_size_text_small);
            font-weight: 400;
            color: var(--text_color_primary_in_button);
          }
          &_text2 {
            font-size: var(--font_size_text_regular);
            font-weight: 600;
            color: var(--text_color_primary_in_button);
            text-align: left;
          }
          &_text3 {
            font-size: var(--font_size_text_regular);
            font-weight: 600;
            color: var(--text_color_primary_in_button);
            text-align: right;
          }
        }
      }
    }
  }
}

@media all and (max-width: 280px) {
  .prize {
    height: 1200px !important;
    &_card {
      &_image {
        width: 80%;
        margin-top: -9px;
      }
      &_top {
        width: 83%;
        position: absolute;
        top: -7px;
      }
      &_rank {
        height: 117px;
        &_zaby {
          top: 30px;
          left: 14px;
          &_count {
            top: 10px;
            left: 5px;
          }
          &_ruby {
            top: -20px;
            left: 83px;
          }
          &_tanba {
            top: -2px;
            left: 165px;
          }
        }
      }
      &_list {
        &_item {
          &_hair {
            margin-right: -20px;
          }
        }
        &_price {
          &_anpha {
            flex-direction: column;
            align-items: baseline;
            &_text1 {
              text-align: left !important;
            }
            &_text2 {
              text-align: left !important;
            }
          }
        }
      }
      &_type {
        &_item {
          &_hair {
            margin-right: -20px;
          }
        }
        &_price {
          &_anpha {
            flex-direction: column;
            align-items: baseline;
            &_text1 {
              text-align: left !important;
            }
            &_text2 {
              text-align: left !important;
            }
          }
        }
      }
      &_captain {
        &_item {
          &_hair {
            margin-right: -20px;
          }
        }
        &_price {
          &_anpha {
            flex-direction: column;
            align-items: baseline;
            &_text1 {
              text-align: left !important;
            }
            &_text3 {
              text-align: left !important;
            }
          }
        }
      }
    }
  }

  .lines_divice {
    display: none;
  }
}

.basic_popups {
  height: 700px;
  &_title {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 25px;
    line-height: 20px;
    &_text {
      font-size: var(--font_size_title_regular);
      font-weight: var(--font_weight_primary);
    }
  }
  &_content {
    margin-bottom: 25px;
    &_text {
      line-height: 22px;
      font-size: var((--font_size_text_regular));
      font-weight: 400;
      margin-bottom: 10px;
    }
  }
  &_bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0px;
    background-color: var(--color_background);
    cursor: pointer;
    border-radius: 6px;
    &_left {
      display: flex;
      justify-content: center;
      align-items: center;
      &_text {
        font-size: var((--font_size_text_regular));
        font-weight: 400;
        color: var(--color_danger);
        padding-left: 5px;
        line-height: 20px;
      }
    }
  }
}

.lines_divice {
  border: 1px dashed #ffffff;
  height: 65px;
  opacity: 0.2;
}

.prize_border {
  border-radius: 14px;
  padding-left: 12px;
  padding-right: 12px;
}

.leader_header {
  position: absolute !important;
  top: 0px;
}
