.splash{
  background-color: white;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999999;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;

  .splashContent{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: var(--space_item);
    font-weight: var(--font_weight_secondary);
  }
}