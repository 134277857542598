#Footer {
  background-color: var(--background_color_component);
  width: 100%;
  margin: auto;
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: end;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  box-shadow: 0px -4px 12px 0px #180d0114;

  .footer_container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    color: var(--text_color_primary_light);
    font-size: var(--font_size_text_small);
    font-weight: var(--font_weight_secondary);
    padding: 0 var(--space_item_primary);
    gap: 6px;

    @media screen and (max-width: 280px) {
      padding: 0 4px;
      gap: 3px;
    }

    .footer_item_active {
      color: var(--color_primary);
    }

    .footer_item {
      position: relative;
      cursor: pointer;
      align-items: center;
      display: flex;
      flex-direction: column;
      gap: 2px;
      width: calc((100% - 70px) / 4);
      height: 100%;
      padding: var(--space_item_primary) 0;

      @media screen and (max-width: 280px) {
        width: calc((100% - 55px) / 4);
      }

      .footer_item_activeLine {
        position: absolute;
        top: 0;
        width: 100%;
        height: 3px;
        border-radius: 0px 0px 8px 8px;
        background-color: var(--color_primary);
      }

      p {
        text-align: center;
      }
    }

    .footer_item_icon {
      height: 24px;
      width: 24px;
    }

    .footer_item_special {
      padding: var(--space_item_primary) 0;
      cursor: pointer;
      width: 70px;
      position: relative;
      display: flex;
      align-items: end;
      justify-content: center;

      p {
        text-align: center;
      }

      @media screen and (max-width: 280px) {
        width: 55px;
      }

      .footer_item_icon_special {
        position: absolute;
        padding: 11px;
        border: 3px solid var(--background_color_component);

        @media screen and (max-width: 280px) {
          padding: 10px;
        }

        background: var(--color_primary_gradiant);
        border-radius: 50%;
        top: -40%;

        svg {
          height: 32px;
          width: 32px;
        }
      }
    }
  }
}